import { useContext, useEffect, useState } from "react";

import { ApiContext } from "../context/ApiContext";

import { AppContext } from "../context/AppContext";

let randomIndex = 0;
export const useRandomTestTitle = () => {
    const { ydlTestList } = useContext(ApiContext);

    if (!ydlTestList.length) return "";

    if (randomIndex === 0) {
        randomIndex = Math.round(Math.random() * ydlTestList.length);
    }

    let randomTest = ydlTestList[randomIndex];

    let firstWord = randomTest.title.split(" ")[0];

    firstWord = firstWord.replace(/[^a-zA-Z ]/g, ""); // clear special characters

    firstWord = firstWord.toLowerCase();

    return "try " + firstWord + "...";
};

export const useSearchTerm = () => {
    const [appState, setAppState] = useContext(AppContext);

    return [
        appState.searchTerm,
        searchTerm => {
            setAppState({
                ...appState,
                searchTerm,
                activePage: 1 // always reset the pagination when searching
            });
        }
    ];
};

export const useActivePage = () => {
    const [appState, setAppState] = useContext(AppContext);
    return [
        appState.activePage,
        activePage => setAppState({ ...appState, activePage })
    ];
};

export const usePaginatedTestList = () => {
    const filteredTestList = useFilteredTestList();
    const [activePage] = useActivePage();
    const pageSize = 10;

    return {
        list: filteredTestList.slice(
            (activePage - 1) * pageSize,
            activePage * pageSize
        ),
        pageSize,
        total: filteredTestList.length,
        from: (activePage - 1) * pageSize + 1,
        to: Math.min(activePage * pageSize, filteredTestList.length)
    };
};

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const useFilteredTestList = () => {
    const api = useContext(ApiContext);

    const { ydlTestList } = api;

    const [searchTerm] = useSearchTerm();

    const cleanSearch = escapeRegExp(searchTerm);

    const regex = new RegExp(cleanSearch, "ig");

    if (searchTerm.length > 2) {
        return sortListKeys(
            ydlTestList.filter(t => t.title.match(regex)).map(t => ({
                ...t,
                title: t.title.replace(
                    regex,
                    match => `<span class="match">${match}</span>`
                )
            }))
        );
    }
    return sortListKeys(ydlTestList);
};

const sortListKeys = list =>
    list.map(t => {
        let sorted = {};
        Object.keys(t)
            .sort()
            .forEach(key => (sorted[key] = t[key]));
        return sorted;
    });

export const useExpandedItems = () => {
    const [state, setState] = useContext(AppContext);

    return [
        state.expandedTestListItems,
        expandedTestListItems => setState({ ...state, expandedTestListItems })
    ];
};

export const useToggleExpanded = () => {
    const [expanded, setExpanded] = useExpandedItems();

    return [
        expanded,
        id =>
            setExpanded(
                expanded.includes(id)
                    ? expanded.filter(idx => id !== idx)
                    : [...expanded, id]
            )
    ];
};

export const useToggleExpandedAll = toggleExpanded => {
    const { ydlTestList } = useContext(ApiContext);
    const [expanded, setExpanded] = useExpandedItems();

    return [
        expanded.length === ydlTestList.length
            ? "all"
            : expanded.length === 0
                ? "none"
                : "nan",
        () => {
            if (expanded.length === ydlTestList.length) {
                setExpanded([]);
            } else {
                setExpanded(ydlTestList.map(t => t.id));
            }
        }
    ];
};

export const useAutofocus = inputEl => {
    useEffect(() => {
        setTimeout(function() {
            inputEl.current.focus();
        }, 10);
    }, []);
};

export const useLoading = () => {
    const { loading } = useContext(ApiContext);

    return [loading];
};

export const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(
        document.documentElement.clientWidth
    );

    const handleWindowResize = () => {
        setWindowWidth(document.documentElement.clientWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return [windowWidth];
};
