import axios from "axios";

import {
    Children,
    createContext,
    createElement,
    useState,
    useEffect
} from "react";

import { API_BASE } from "../constants";

const instance = axios.create({
    baseURL: API_BASE,
    timeout: 30000
});

const data = {
    loading: true,
    ydlTestList: []
};

export const ApiContext = createContext(data);

export function ApiContextProvider({ children }) {
    return createElement(
        ApiContext.Provider,
        { value: useApi() },
        Children.only(children)
    );
}

function useApi() {
    const [api, setApi] = useState(data);

    async function fetchYdlTestList() {
        try {
            setApi({
                ...api,
                loading: true
            });
            const response = await instance.get("ydltest");
            setApi({
                ...api,
                loading: false,
                ydlTestList: response.data
            });
        } catch (e) {
            setApi({
                ...api,
                loading: false
            });
        }
    }

    useEffect(() => {
        fetchYdlTestList();
    }, []);

    return api;
}
