import { createContext, Children, createElement, useState } from "react";

const data = {
    searchTerm: "",
    activePage: 1,
    expandedTestListItems: []
};

export const AppContext = createContext(data);

export function AppContextProvider({ children }) {
    return createElement(
        AppContext.Provider,
        { value: useAppState() },
        Children.only(children)
    );
}

function useAppState() {
    const [appState, setAppState] = useState(data);

    return [appState, setAppState];
}
