import React from "react";

import YdlTestList from "./components/YdlTestList";
import AppLoader from "./components/AppLoader";

import { ApiContextProvider } from "./context/ApiContext";
import { AppContextProvider } from "./context/AppContext";

import "./styles/theme.scss";

function App() {
    return (
        <AppContextProvider>
            <ApiContextProvider>
                <AppLoader>
                    <YdlTestList />
                </AppLoader>
            </ApiContextProvider>
        </AppContextProvider>
    );
}

export default App;
