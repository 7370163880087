import React from "react";
import { FaSpinner } from "react-icons/fa";

import "./styles.scss";

export default function Loader() {
    return (
        <div className="loader">
            <FaSpinner />
        </div>
    );
}
