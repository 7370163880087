import React, { useRef } from "react";

import { useRandomTestTitle, useSearchTerm } from "../../hooks";

import { IoIosClose } from "react-icons/io";

import "./styles.scss";

export default function SearchBox() {
    const [searchTerm, setSearchTerm] = useSearchTerm();

    const inputEl = useRef(null);

    const placeholder = useRandomTestTitle();

    const onClear = () => {
        setSearchTerm("");
        inputEl.current.focus();
    };

    return (
        <div className="searchbox">
            <input
                value={searchTerm}
                ref={inputEl}
                placeholder={placeholder}
                onChange={e => {
                    setSearchTerm(e.target.value);
                }}
            />
            {searchTerm.length > 0 && (
                <IoIosClose
                    size={40}
                    className="search-clear"
                    onClick={onClear}
                />
            )}
            {searchTerm.length > 0 && searchTerm.length < 3 ? (
                <span className="more-to-go">
                    {3 - searchTerm.length} more characters to go
                </span>
            ) : null}
        </div>
    );
}
