import React, { Fragment } from "react";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import "./styles.scss";
import cn from "classnames";

function createMarkup(item) {
    return { __html: item.title };
}

function formatName(key) {
    if (key === "discount_category") return "Test Category";

    return key.replace(/_/g, " ");
}

export default function ListItem({ expanded, item, onItemClick }) {
    return (
        <li
            onClick={() => onItemClick()}
            className={cn("list-item", {
                expanded,
                "has-notes": item.notes && item.notes.length
            })}
        >
            <div className="title">
                {expanded ? <FaMinusSquare /> : <FaPlusSquare />}
                <div
                    className="inner-title"
                    dangerouslySetInnerHTML={createMarkup(item)}
                />
            </div>
            {expanded ? (
                <Fragment>
                    <ul>
                        {Object.keys(item)
                            .filter(
                                k =>
                                    ![
                                        "title",
                                        "id",
                                        "created_at",
                                        "updated_at",
                                        "deleted_at",
                                        "notes"
                                    ].includes(k)
                            )
                            .map(key => (
                                <li key={key}>
                                    <span className="title">
                                        {formatName(key)}
                                    </span>
                                    <span className="value">{item[key]}</span>
                                </li>
                            ))}
                    </ul>
                    {item.notes && item.notes.length && <p>{item.notes}</p>}
                </Fragment>
            ) : null}
        </li>
    );
}
