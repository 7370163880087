import React from "react";

import SearchBox from "../SearchBox";

import {
    usePaginatedTestList,
    useActivePage,
    useToggleExpanded,
    useToggleExpandedAll,
    useWindowWidth
} from "../../hooks";

import Pagination from "react-js-pagination";

import { FaMinusSquare, FaPlusSquare, FaRegPlusSquare } from "react-icons/fa";

import ListItem from "../ListItem";

import "./styles.scss";

function YdlTestList() {
    const pagination = usePaginatedTestList();
    const [activePage, setActivePage] = useActivePage();
    const [expanded, toggleExpanded] = useToggleExpanded();
    const [expandedAll, toggleExpandAll] = useToggleExpandedAll();
    const [windowWidth] = useWindowWidth();

    return (
        <div>
            <h1 className="test-list-title">Search our test guide</h1>
            <SearchBox />
            <div
                className="expand-all"
                onClick={() => {
                    toggleExpandAll();
                }}
            >
                {
                    {
                        none: <FaPlusSquare />,
                        all: <FaMinusSquare />,
                        nan: <FaRegPlusSquare />
                    }[expandedAll]
                }
                expand all
            </div>
            {pagination.list.length ? (
                <div>
                    <ul className="ydl-test-list">
                        {pagination.list.map(t => (
                            <ListItem
                                key={t.id}
                                item={t}
                                onItemClick={() => toggleExpanded(t.id)}
                                expanded={expanded.includes(t.id)}
                            />
                        ))}
                    </ul>
                    <Pagination
                        innerClass="ydl-pagination"
                        activePage={activePage}
                        itemsCountPerPage={pagination.pageSize}
                        totalItemsCount={pagination.total}
                        pageRangeDisplayed={windowWidth < 480 ? 2 : 3}
                        onChange={setActivePage}
                        itemClassFirst="first"
                        itemClassLast="last"
                    />
                    <p className="showing-from">
                        Showing from {pagination.from} to {pagination.to} out of{" "}
                        {pagination.total}
                    </p>

                    <p className="info">
                        Tests carrying <b>#</b> are referred to our
                        internationally accredited reference laboratories.
                    </p>
                    <p className="info">
                        Tests marked with <b>*</b> are under the scope of ISO
                        15189 accreditation.
                    </p>
                </div>
            ) : (
                <div className="no-records">
                    No result matches your search, try different word
                </div>
            )}
        </div>
    );
}

export default YdlTestList;
